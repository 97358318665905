/* HomeStyles.css */

.home {
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .headerContainer {
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }
  
  .bg-white {
    margin-bottom: 1rem;
  }
  
  .btn-contact {
    background-color: #ff4500; /* Adjust button color */
    color: white;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-contact:hover {
    background-color: #ff5733; /* Adjust hover color */
  }
  

/* About.css */

.about-container {
    margin-bottom: 15rem;
    text-align: center;
    padding: 2rem;
  }
  
  .about-container h4 {
    font-weight: bold;
    margin: 2rem 0;
    font-size: 2rem;
  }
  
  .about-container p {
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
  }
  
  .about-container h5 {
    font-weight: bold;
    margin: 2rem 0;
    font-size: 1.75rem;
  }
  
  @media (max-width: 600px) {
    .about-container {
      margin-top: 0;
    }
  
    .about-container h4 {
      font-size: 1.5rem;
    }
  }
  